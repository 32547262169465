import { TableCell, Typography } from '@mui/material'
import { colors } from '@orus.eu/pharaoh'

type LabelCellProps = {
  text: string
  headerWidth?: string
}

export function TableCellLabel({ text, headerWidth = '240px' }: LabelCellProps): JSX.Element {
  return (
    <TableCell width={headerWidth}>
      <Typography variant="subtitle2" sx={{ color: colors.gray[600], textTransform: 'uppercase' }}>
        {text}
      </Typography>
    </TableCell>
  )
}
